<template>
  <v-card
    elevation="0"
    :class="`cards pb-3 ${hasToUpgrade ? 'cards-plan-gradient' : ''}`"
    height="184"
  >
    <v-card-title :class="`${card.isEmpty ? 'empty-color' : ''}`">
      <v-icon size="30" class="mr-2" :color="iconColor">
        {{ card.icon }}
      </v-icon>
      <span class="card-font" :style="`color: ${titleColor}`">
        {{ card.title }}
      </span>
    </v-card-title>

    <v-card-subtitle class="card-font py-0" v-if="!hasToUpgrade">
      <div class="d-flex mt-5">
        <span
          class="mr-2"
          :style="`
            height: 29px;
            font-family: Montserrat;
            font-size: 24px;
            ${card.isEmpty ? 'color: #979797' : 'color: black;'}`"
        >
          {{ isEmptyState ? card.emptyStateDefaultValue : getCountUsers(card) }}
        </span>
        <v-chip
          color="#DEFFE2"
          class="card-subtitle"
          :style="`color: ${card.isEmpty ? '#979797' : '#696969'}`"
          >{{ $t("common.users") }}</v-chip
        >
      </div>
    </v-card-subtitle>

    <v-card-text class="py-0 d-flex flex-column justify-start align-start">
      <p
        class="my-0 card-font description-text"
        :style="`color: ${descriptionColor}`"
        v-html="hasToUpgrade ? card.hasToUpgradeText : card.description"
      ></p>

      <div
        class="avatar-container d-flex align-start justify-start px-1"
        v-if="hasToUpgrade"
      >
        <img
          v-for="(user, index) in firstFourUsersMock"
          :key="index"
          :src="user.photo"
          class="avatar-content"
        />
        <div class="avatar-content avatar-content-text">+1</div>
      </div>
    </v-card-text>

    <v-card-actions v-if="hasToUpgrade" class="align-center justify-center">
      <v-btn
        block
        :id="`upgrade-indicators-${card.emptyActionText}`"
        color="white"
        class="text-none text-body-2 font-weight-medium pa-0 btns mx-2"
        elevation="0"
        @click="goToSelfCheckout"
      >
        <span class="ml-3 upgrade-cta-btn">
          {{ $t("common.upgradeCTA") }}
        </span>
        <v-icon right class="mx-3" size="16">ph-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-else-if="card.isEmpty"
      class="align-center justify-center"
    >
      <v-btn
        block
        color="#F1E1F7"
        class="text-none text-body-2 font-weight-medium pa-0 btns mx-2"
        @click="card.emptyAction()"
        elevation="0"
      >
        <span class="text-empty-state ml-3"
          >{{ $t(`action.${card.emptyActionText}`) }}
        </span>
        <v-icon right class="mx-3" size="16">ph-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions v-else>
      <div
        v-if="card.percentValues"
        style="bottom: 6px; width: 100%"
        class="px-2"
      >
        <v-progress-linear
          :color="`${card.isEmpty ? '#979797' : '#5B36C3'}`"
          class="white--text"
          :value="card.percentValues"
          height="5"
          rounded
          style="margin-bottom: 7px !important"
        ></v-progress-linear>
        <span
          v-html="card.subDescription"
          class="card-sub-description"
          style="font-family: Montserrat"
        ></span>
      </div>

      <div
        v-else-if="!!card.users?.length"
        class="d-flex align-start justify-start px-2"
        style="position: absolute; bottom: 5px"
      >
        <div v-for="(user, index) in card.users" :key="index">
          <img
            :src="user.photo"
            :class="[
              'card-user-photo',
              { 'card-user-photo-offset': index > 0 },
            ]"
            v-if="index < 5"
          />
          <div
            v-if="index === 5"
            class="card-user-total-length white--text d-flex justify-center align-center font-weight-medium"
          >
            +{{ card.users.length - 1 }}
          </div>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import { productsInPlan } from "@/helpers/services/products";

export default {
  name: "IndicatorsCard",
  props: {
    card: { type: Object, required: true },
    isEmptyState: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["hasConectaControl", "company", "users", "currentUser"]),

    hasToUpgrade() {
      const { current_plan } = this.company;

      if (!current_plan || this.card.product == productsInPlan.conecta_suite)
        return false;

      const productInPlan = current_plan.plan.products.map(
        (product) => product.name
      );

      return !productInPlan.includes(this.card.product);
    },

    iconColor() {
      if (this.hasToUpgrade) return "#fff";
      else if (this.card.isEmpty) return "#979797";

      return "#5B36C3";
    },

    titleColor() {
      if (this.hasToUpgrade) return "#fff";
      else if (this.card.isEmpty) return "#6D6D6D";

      return "#696969";
    },

    descriptionColor() {
      if (this.hasToUpgrade) return "#fff";
      else if (this.card.isEmpty) return "#979797";

      return "#696969";
    },

    firstFourUsersMock() {
      return this.users.slice(0, 4);
    },
  },
  methods: {
    getCountUsers(card) {
      return card.usersCount ? card.usersCount : `${card.percentValues}%`;
    },

    goToSelfCheckout() {
      const { main_domain } = this.company;
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: `dash_indicators_${this.card.emptyActionText}`,
        main_domain: main_domain,
        email: this.currentUser.email,
        description: `Clicou no botão 'Fazer upgrade' dos cards de indicadores (dashboard)`,
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },
  },
};
</script>

<style>
.description-text {
  height: 34px;
}

.avatar-container {
  margin: 6px 0 4px 0;
}

.avatar-content {
  width: 23px;
  height: 23px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-left: -5px;
}

.avatar-content-text {
  font-family: Manrope;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  color: white;
  background: #5d43a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards {
  border: 0.95px solid #ebebeb !important;
  border-radius: 8.48px !important;
  box-shadow: 0px 5.71px 28.56px 0px #0000000f !important;
}
.cards-plan-gradient {
  background-color: #5b36c3;
  background-image: linear-gradient(#5b36c3, #e2d8ff);
  border: 0.95px solid #ebebeb;
}
.card-font {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.96px;
  text-align: left;
}
.card-subtitle {
  width: 65px !important;
  height: 18px !important;
  top: 1px !important;
  padding: 0px 1px 0px 5px !important;
  border-radius: 8px !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16.96px !important;
}
.card-sub-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.96px;
  text-align: left;
  color: #8a8a8a;
}
.card-user-photo {
  width: 28px;
  height: 28px;
  border: 1.4px solid #ffffff;
  box-shadow: 0px 2.8px 2.8px 0px #00000014;
  border-radius: 50%;
}
.card-user-photo-offset {
  margin-left: -6px;
}
.card-user-total-length {
  width: 28px;
  height: 28px;
  background: #5d43a6a6 60% !important;
  border: 1.4px solid #ffffff !important;
  box-shadow: 0px 2.8px 2.8px 0px #00000014 !important;
  border-radius: 50% !important;
  font-family: Manrope !important;
  font-size: 10.5px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  text-align: left !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  color: white;
}
.btns {
  color: #5b36c3 !important;
  min-width: 146px !important;
  height: 32px !important;
}

.upgrade-cta-btn {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #5b36c3;
}

.text-empty-state {
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: left !important;
}
</style>
