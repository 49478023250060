<template>
  <v-dialog
    v-model="showOnBoarding"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile dark class="d-flex flex-column">
      <v-toolbar
        flat
        dark
        color="#311F50"
        class="justify-start"
        max-height="66px"
      >
        <v-spacer />
        <v-toolbar-title>Bem vindo ao Conecta Suite</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="my-auto">
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTCbMQwMNHvCoK4J0nG4mIrGjQnItPsetLIVaHrXOZ6XNNVJH1A-xGiYOXOUimAtUPeThseujgn2pHu/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="100%"
          height="780"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { disableOnboarding } from "@/helpers/services/onboarding";
export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["showOnBoarding"]),
  },
  methods: {
    ...mapMutations(["closeOnBoarding"]),
    close() {
      disableOnboarding();
      this.closeOnBoarding();
    },
  },
};
</script>
