import axios from "axios";

export function disableOnboarding() {
  localStorage.removeItem("show_onboarding");
  localStorage.setItem("show_onboarding", false);

  let token = localStorage.getItem("token");

  let url = process.env.VUE_APP_API_BASE_URL + "/user/onboarding";

  let params = {};

  axios
    .post(url, params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then(() => {})
    .catch((err) => console.log("disableOnboarding", err));
}
