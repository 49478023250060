<template>
  <v-data-table
    :headers="headers"
    :items="usersToDisplay"
    :items-per-page="5"
    :show-select="!!usersToDisplay.length"
    item-key="key"
    :loading="loading || loadingSign"
    :hide-default-header="hideHeader"
    fixed-header
    height="244"
    class="elevation-0 pt-4 pb-2 rounded-lg table-font"
  >
    <template v-slot:top>
      <div class="d-flex align-center px-4">
        <div class="titles-container">
          <h2 class="title">{{ $t("common.collaborators") }}</h2>
          <p class="subtitle mb-4">
            {{ $t("usersTableMetrics.subtitle") }}
          </p>
        </div>
        <v-spacer></v-spacer>

        <!-- TODO: SEARCH AND EXPORT -->
        <!-- <div class="d-flex align-center">
          <v-text-field
            v-model="search"
            class="mx-2 search"
            flat
            dense
            hide-details
            :label="$t('common.search')"
            solo
            background-color="#fff"
            id="serch-metrics"
          >
            <template v-slot:prepend-inner>
              <v-icon size="16" left>ph-magnifying-glass</v-icon>
            </template>
          </v-text-field>

          <v-btn class="text-none cell-font" text elevation="0">
            <v-icon color="#6D6D6D" left size="15">ph-download-simple</v-icon>
            <span class="export-btn">{{ $t("action.export") }}</span>
          </v-btn>
        </div> -->
      </div>

      <v-item-group mandatory v-model="activeMenu" class="px-4">
        <div
          class="tab-line mb-4 mt-2 px-4 d-flex align-center justify-space-between"
        >
          <div v-for="(_, key) in tableMenu" :key="key" class="py-2">
            <v-item v-slot="{ active, toggle }" :value="key">
              <v-card
                :color="active ? '#bb6bd933' : ''"
                class="d-flex align-center justify-center menu-border"
                elevation="0"
                min-height="31"
                @click="toggle"
              >
                <span
                  :class="`menu-text ${
                    active ? 'active-text' : 'inactive-text'
                  }`"
                >
                  {{ $t(`usersTableMetrics.${key}`) }}
                  <v-icon
                    v-if="tableMenu[key].upsell"
                    :color="activeMenu == key ? '#5B36C3' : '#979797'"
                    class="ml-1"
                  >
                    ph-lock
                  </v-icon>
                </span>
              </v-card>
            </v-item>
          </div>
        </div>
      </v-item-group>
    </template>

    <template v-slot:no-data>
      <EmptyStateTableData
        v-if="callUpsell"
        title-key="userInformations.upsellState.title"
        :subtitle-key="tableMenu[activeMenu].upsellTextKey"
        action-text-key="common.knowPlans"
        state-icon-key="ph-rocket-launch"
        icon-color="#5B36C3"
        :is-selected="true"
        @click:action="knowPlans(tableMenu[activeMenu])"
      />
      <EmptyStateTableData
        v-else
        :title-key="emptyState[activeMenu].noData"
        :subtitle-key="emptyState[activeMenu].cta"
        :action-text-key="emptyState[activeMenu].action"
        state-icon-key="ph-shield-warning"
        icon-color="#6D6D6D"
        @click:action="emptyState[activeMenu].method"
      />
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <v-row class="align-center py-2" no-gutters>
        <v-avatar left size="28" class="mr-2">
          <img :src="item.photo" :alt="item.name" />
        </v-avatar>
        <span class="text-capitalize cell-font">{{ item.name }}</span>
      </v-row>
    </template>

    <template v-slot:[`item.email`]="{ item }">
      <span class="cell-font">{{ item.email }}</span>
    </template>

    <template v-slot:[`item.departament`]="{ item }">
      <span class="cell-font">{{ item.departament }}</span>
    </template>

    <template v-slot:[`item.applied_signature_key`]="{ item }">
      <span class="cell-font">
        {{ getSignatureModelName(item.applied_signature_key) }}
      </span>
    </template>

    <template v-slot:[`item.applied_vacation_key`]="{ item }">
      <span class="cell-font">
        {{ getVacationModelName(item.applied_vacation_key) }}
      </span>
    </template>

    <template v-slot:[`item.vacation_init_date`]="{ item }">
      <span class="cell-font">
        {{ formatDate(item.vacation_init_date) }}
      </span>
    </template>

    <template v-slot:[`item.vacation_end_date`]="{ item }">
      <span class="cell-font">
        {{ formatDate(item.vacation_end_date) }}
      </span>
    </template>

    <template v-slot:[`item.rule_group_names`]="{ item }">
      <ul class="py-2 rules">
        <div v-if="item.rule_group_names.length">
          <li
            class="cell-font"
            v-for="(name, i) in item.rule_group_names"
            :key="i"
          >
            {{ name }}
          </li>
        </div>
        <li class="remove-bullets" v-else>-</li>
      </ul>
    </template>

    <template v-slot:[`item.rule_group`]="{ item }">
      <ul class="py-2 remove-bullets rules">
        <div v-if="item.rule_group.length">
          <li
            class="cell-font li-margin"
            v-for="(rule, i) in item.rule_group"
            :key="i"
          >
            <v-chip
              :color="rule.is_active ? '#42A574' : '#FF8B73'"
              outlined
              :class="`${
                rule.is_active ? 'chip-active' : 'chip-inactive'
              } d-flex justify-center align-center`"
              label
            >
              <span class="poppins-10-16">
                {{ $t(`common.${rule.is_active ? "active" : "inactive"}`) }}
              </span>
            </v-chip>
          </li>
        </div>
        <li v-else class="cell-font li-margin">-</li>
      </ul>
    </template>

    <template v-slot:[`item.permission`]="{ item }">
      <ul class="py-2">
        <li
          class="cell-font"
          v-for="(_, value) in getAdminPermissions(item)"
          :key="value"
        >
          {{ $t(`permissions.${value}`) }}
        </li>
      </ul>
    </template>

    <template v-slot:[`item.delegates`]="{ item }">
      <ul class="py-2">
        <li
          class="cell-font"
          v-for="(delegate, index) in item.delegates"
          :key="index"
        >
          {{ delegate }}
        </li>
      </ul>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatDate,
  checkExpiredDate,
  goToControl,
} from "@/helpers/services/utils";
import { getAnalytics, logEvent } from "firebase/analytics";
import EmptyStateTableData from "@/components/general/EmptyStateTableData.vue";

export default {
  name: "UsersTableMetrics",
  components: { EmptyStateTableData },
  data() {
    return {
      search: "",
      activeMenu: null,

      emptyState: {
        signature: {
          noData: "userInformations.emptyState.signature.noData",
          cta: "userInformations.emptyState.signature.cta",
          action: "action.applySignature",
          method: () => this.routerPush("/sign/email-signature/templates"),
        },
        permissions: {
          noData: "userInformations.emptyState.permissions.noData",
          cta: "userInformations.emptyState.permissions.cta",
          action: "userInformations.emptyState.permissions.action",
          method: () => this.routerPush("/settings/user-permissions"),
        },
        vacation: {
          noData: "userInformations.emptyState.vacation.noData",
          cta: "userInformations.emptyState.vacation.cta",
          action: "action.applyVacationMessage",
          method: () => this.routerPush("/sign/vacation-message"),
        },
        delegates: {
          noData: "userInformations.emptyState.delegates.noData",
          cta: "userInformations.emptyState.delegates.cta",
          action: "userInformations.emptyState.delegates.action",
          method: () => this.routerPush("/users"),
        },
        vacationControl: {
          noData: "userInformations.emptyState.vacationControl.noData",
          cta: "userInformations.emptyState.vacationControl.cta",
          action: "action.configVacationMessage",
          method: () => goToControl("users"),
        },
        shifts: {
          noData: "userInformations.emptyState.shifts.noData",
          cta: "userInformations.emptyState.shifts.cta",
          action: "userInformations.emptyState.shifts.action",
          method: () => goToControl("groups"),
        },
      },
    };
  },

  methods: {
    formatDate,

    ...mapActions([
      "getDefaultTemplates",
      "getCustomTemplates",
      "getVacationTemplates",
      "getDelegates",
    ]),

    getSignatureModelName(signature_id) {
      if (!signature_id) return "-";

      const signTemplate = this.allTemplates.find(
        (template) => template.id === signature_id
      );

      return signTemplate ? signTemplate.name : "-";
    },

    getAdminPermissions(user) {
      return Object.keys(user.permission).reduce((acc, key) => {
        if (
          user.permission[key] === "admin" &&
          ![
            "conecta_ad_sync",
            "conecta_control_mobile",
            "integracao_ponto_mais",
          ].includes(key)
        ) {
          acc[key] = "admin";
        }
        return acc;
      }, {});
    },

    getVacationModelName(vacation_key) {
      if (!vacation_key) return "-";

      const vacationTemplate = this.allVacationTemplates.find(
        (template) => template.key === vacation_key
      );

      return vacationTemplate ? vacationTemplate.name : "-";
    },

    routerPush(path) {
      this.$router.push(path);
    },

    getControlUserByEmail(email) {
      return this.controlUsers.find((user) => user.email === email);
    },

    controlUsersInVacation() {
      const emailsFromUsersInVacation = this.usersInActiveVacation.map(
        (user) => user.email
      );
      return this.users
        .filter((suiteUser) =>
          emailsFromUsersInVacation.includes(suiteUser.email)
        )
        .map((suiteUser) => {
          const matchingControlUser =
            this.usersInActiveVacation.find(
              (user) => user.email === suiteUser.email
            ) || {};

          const { start_vacation_date, end_vacation_date } =
            matchingControlUser;
          suiteUser.vacation_end_date = end_vacation_date;
          suiteUser.vacation_init_date = start_vacation_date;

          return suiteUser;
        });
    },

    suiteUsersWithShifts() {
      if (!this.controlUsers.length) return [];
      return this.users.map((suiteUser) => {
        const matchingControlUser = this.getControlUserByEmail(suiteUser.email);
        const { rule_group = [] } = matchingControlUser || {};
        const rule_group_names = rule_group.map((item) => item.name);
        return { ...suiteUser, rule_group, rule_group_names };
      });
    },

    formatRuleGroupStatus(rules) {
      return rules.map(
        (rule) => !!rule.is_active || !!rule.in_activation_period
      );
    },

    knowPlans(menu) {
      const analytics = getAnalytics();
      logEvent(analytics, "go_to_self_checkout", {
        click: menu,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: `Clicou para conhecer planos na aba ${this.activeMenu} dos colaboradores (dashboard)`,
      });
      this.$router.push("/billing/upgrade");
    },
  },

  computed: {
    ...mapGetters([
      "loading",
      "loadingSign",
      "users",
      "allTemplates",
      "allVacationTemplates",
      "usersDelegates",
      "controlUsers",
      "usersInActiveVacation",
      "mainDomain",
      "currentUser",
      "hasConectaSign",
      "hasConectaControl",
    ]),

    tableMenu() {
      return Object.fromEntries(
        Object.entries({
          signature: {
            key: "signature",
            upsell: !this.hasConectaSign,
            upsellTextKey: "userInformations.upsellState.signature.call",
          },
          permissions: {
            key: "permissions",
          },
          vacation: {
            key: "vacation",
          },
          delegates: {
            key: "delegates",
          },
          vacationControl: {
            key: "vacationControl",
            upsell: !this.hasConectaControl,
            upsellTextKey: "userInformations.upsellState.vacationControl.call",
          },
          shifts: {
            key: "shifts",
            upsell: !this.hasConectaControl,
            upsellTextKey: "userInformations.upsellState.shifts.call",
          },
        }).sort((a, b) => !!a[1].upsell - !!b[1].upsell)
      );
    },

    availableHeaders() {
      return {
        signature: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("usersTableMetrics.signModel"),
            value: "applied_signature_key",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
        ],
        permissions: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("sidebar.permissions"),
            value: "permission",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
        ],
        vacation: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("usersTableMetrics.signModel"),
            value: "applied_vacation_key",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
          {
            text: this.$t("userInformations.endDate"),
            value: "vacation_end_date",
            align: "start",
          },
        ],
        delegates: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("usersTableMetrics.delegates"),
            value: "delegates",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
        ],
        vacationControl: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("userInformations.departament"),
            value: "departament",
            align: "start",
          },
          {
            text: this.$t("userInformations.initVacationDate"),
            value: "vacation_init_date",
            align: "start",
          },
          {
            text: this.$t("userInformations.endVacationDate"),
            value: "vacation_end_date",
            align: "start",
          },
        ],
        shifts: [
          {
            text: this.$t("common.name"),
            value: "name",
            align: "start",
          },
          {
            text: this.$t("common.email"),
            value: "email",
            align: "start",
          },
          {
            text: this.$t("common.shift"),
            value: "rule_group_names",
            align: "start",
          },
          {
            text: this.$t("userInformations.shiftStatus"),
            value: "rule_group",
            align: "start",
          },
        ],
      };
    },

    headers() {
      return this.availableHeaders[this.activeMenu];
    },

    usersToDisplay() {
      if (this.callUpsell) return [];

      const usersPerMenu = {
        signature: this.usersWithSignApplied,
        permissions: this.usersWithPermission,
        vacation: this.usersWithVacationApplied,
        delegates: this.usersDelegates,
        vacationControl: this.controlUsersInVacation(),
        shifts: this.suiteUsersWithShifts(),
      };

      return usersPerMenu[this.activeMenu];
    },

    usersWithSignApplied() {
      return this.users.filter((user) => user.applied_signature_key);
    },

    usersWithPermission() {
      return this.users.filter((user) =>
        Object.values(user.permission).includes("admin")
      );
    },

    usersWithVacationApplied() {
      return this.users.filter(
        (user) =>
          user.applied_vacation_key && !checkExpiredDate(user.vacation_end_date)
      );
    },

    hideHeader() {
      return !this.usersToDisplay.length || this.callUpsell;
    },

    callUpsell() {
      return this.tableMenu[this.activeMenu].upsell;
    },
  },

  beforeMount() {
    this.activeMenu = this.activeMenu || Object.keys(this.tableMenu)[0];
    if (!this.allTemplates.length) {
      this.getDefaultTemplates();
      this.getCustomTemplates();
    }
    if (!this.allVacationTemplates.length) {
      this.getVacationTemplates();
    }

    this.getDelegates();
  },
};
</script>
<style scoped>
.search {
  border-radius: 16px;
  width: 184px;
  border: 1px solid #dbdbdb;
}

.titles-container {
  max-width: 406.63px;
}

.export-btn {
  color: #6d6d6d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.title {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 8px 0 6px 0;
}

.subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #696969;
}

.menu-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.active-text {
  color: #5b36c3;
}

.inactive-text {
  color: #979797;
}

.tab-line {
  border: 1px solid #5b36c354;
  border-radius: 10px;
  margin: 0px 1px;
}

.menu-border {
  border-radius: 4px !important;
  padding: 6px 13px !important;
}

::v-deep .v-data-table-header th {
  background-color: white !important;
}

::v-deep .v-data-footer div {
  color: #696969;
  font-family: "Poppins", sans-serif;
}

::v-deep
  .search
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#serch-metrics {
  padding: 0px;
  font-size: 12px;
}

::v-deep
  .search
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input#serch-metrics::content {
  color: #747474;
  font-size: 12px;
}

::v-deep .search .v-input__control .v-input__slot .v-text-field__slot label {
  color: #747474;
  font-size: 12px;
  display: block;
  text-align: center;
  line-height: 150%;
}

.table-font {
  font-family: "Poppins", sans-serif;
}

.cell-font {
  font-size: 12px;
}

.poppins-10-16 {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 16.96px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.poppins-14-21 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
</style>

<style>
.table-font .v-data-table__progress {
  position: sticky;
  top: 48px;
  z-index: 1;
}
.remove-bullets {
  list-style-type: none;
}
.chip-active {
  width: 45px !important;
  height: 16px !important;
  padding: 6px 4px 4px 4px !important;
  border-radius: 4px !important;
  border: 1px solid !important;
}

.chip-inactive {
  width: 53px !important;
  height: 16px !important;
  padding: 4px 0px 4px 0px !important;
  border-radius: 4px !important;
  border: 1px solid !important;
}

.rules li {
  margin-top: 10px;
}

.rules li:first-child {
  margin-top: 0;
}
</style>
