import { render, staticRenderFns } from "./EmptyStateTableData.vue?vue&type=template&id=e24e48da&scoped=true"
import script from "./EmptyStateTableData.vue?vue&type=script&lang=js"
export * from "./EmptyStateTableData.vue?vue&type=script&lang=js"
import style0 from "./EmptyStateTableData.vue?vue&type=style&index=0&id=e24e48da&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24e48da",
  null
  
)

export default component.exports